import React, { useState } from 'react';
import {db,storage} from "../firebase"
import {v4} from "uuid"
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Registration = ({ dimensions }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [image, setImage] = useState( '');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const img=ref(storage,`Complaint/${v4()}`)
      uploadBytes(img,e.target.files[0]).then(data=>{
        // console.log(data,"img")
        getDownloadURL(data.ref).then(val =>{
          // console.log(val)
          setImage(val);
        })
      })
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {    
      console.log(formData,image);
      await addDoc(collection(db, 'registration'), {
        ...formData,
        image
      });

      alert('Data has been submitted!');
      setFormData({
        fullName: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      });
      setImage("");
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="registration" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexShrink: "200px", padding: "2rem 9%", width: "100%" }}>
      <div style={{ backgroundColor: "white", boxShadow: "0px 0px 10px #2e0100", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", maxWidth: "600px", width: "100%", padding: "20px", border: "1px solid gray" }}>
        <p>To contact us, please fill in the form and email it to us.</p>
        <form style={{ display: "flex", flexDirection: "column", gap: "20px" }} onSubmit={handleSubmit}>
          <div style={{ width: "100%" }}>
            <input
              name="fullName"
              placeholder='Enter your Full name'
              style={{ padding: "10px 20px", width: dimensions.width <= 500 ? "100%" : "50%" }}
              value={formData.fullName}
              onChange={handleChange}
              required
            />
            <input
              name="email"
              placeholder='Enter your Email ID'
              style={{ padding: "10px 20px", width: dimensions.width <= 500 ? "100%" : "50%", marginTop: dimensions.width <= 500 ? "20px" : "0px" }}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <input
            name="phone"
            placeholder='Phone Number'
            style={{ padding: "10px 20px" }}
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <input
            name="subject"
            placeholder='Subject'
            style={{ padding: "10px 20px" }}
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <input
            name="message"
            placeholder='Message'
            style={{ padding: "30px 20px" }}
            value={formData.message}
            onChange={handleChange}
            required
          />
          <input
            type='file'
            onChange={handleImageChange}
            style={{ padding: "5px 5px" }}
          />
          <input type="submit" style={{ padding: "10px 20px" }} />
        </form>
      </div>
    </div>
  );
};

export default Registration;
