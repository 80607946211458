import React, { useState } from 'react';
import { db } from "../firebase";
import { collection, addDoc } from 'firebase/firestore';

const ContactUs = ({ dimensions }) => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: '',
    Subject: '',
    Message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'Contactus'), {
        ...formData
      });

      alert('Data has been submitted!');
      setFormData({
        Name: '',
        Email: '',
        Phone: '',
        Subject: '',
        Message: ''
      });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className='contactus' style={{ padding: "2rem 9%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "2rem", flexDirection: "column" }}>
      <div style={{ justifyContent: "space-evenly", display: "flex", flexWrap: "wrap", gap: "50px", width: "100%" }}>
        <div>
          <h2 style={{ textAlign: "left", textDecoration: "underline", color: "blue" }}>Camp Office</h2>
          <div>
            <ul style={{ listStyle: "none", padding: "0px", textAlign: "left" }}>
              <li>C Block flat number 602 Sun City district Rohtak, <br /> state Haryana, BHARAT -124001</li>
              <li>7082179144, 9053100144</li>
              <li>humandevelopmentresearchservic@gmail.com</li>
            </ul>
          </div>
        </div>
        <div>
          <h2 style={{ textAlign: "left", textDecoration: "underline", color: "blue" }}>National Office</h2>
          <div>
            <ul style={{ listStyle: "none", padding: "0px", textAlign: "left" }}>
              <li>C Block flat number 602 Sun City district Rohtak, <br /> state Haryana, BHARAT -124001</li>
              <li>7082179144, 9053100144</li>
              <li>humandevelopmentresearchservic@gmail.com</li>
            </ul>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "white", display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center", width: "100%" }}>
        <form
          style={{
            border: "1px solid red",
            boxShadow: "0px 0px 10px red",
            color: "blue",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            marginTop: "80px",
            backgroundColor: "inherit",
            borderRadius: "5px",
            marginBottom: "70px",
            width: dimensions.width <= 500 ? "100%" : "80%"
          }}
          onSubmit={handleSubmit}
        >
          <h2 style={{ color: "red", padding: "20px" }}>
            To contact us, please fill in the form and email it to us.
          </h2>
          <input
            name="Name"
            placeholder='Enter Your Full Name'
            style={{ width: "90%", padding: "10px", fontSize: "18px" }}
            value={formData.Name}
            onChange={handleChange}
            required
          />
          <input
            name="Email"
            placeholder='Enter Your Email ID'
            style={{ width: "90%", padding: "10px", fontSize: "18px" }}
            value={formData.Email}
            onChange={handleChange}
            required
          />
          <input
            name="Phone"
            placeholder='Phone Number'
            style={{ width: "90%", padding: "10px", fontSize: "18px" }}
            value={formData.Phone}
            onChange={handleChange}
            required
          />
          <input
            name="Subject"
            placeholder='Subject'
            style={{ width: "90%", padding: "10px", fontSize: "18px" }}
            value={formData.Subject}
            onChange={handleChange}
            required
          />
          <input
            name="Message"
            placeholder='Message'
            style={{ width: "90%", padding: "10px", fontSize: "18px" }}
            value={formData.Message}
            onChange={handleChange}
            required
          />
          <button
            style={{
              marginTop: "20px",
              marginBottom: "30px",
              backgroundColor: "red",
              padding: "10px",
              width: "40%",
              color: "white",
              fontWeight: "700",
              borderRadius: "5px",
              border: "none",
              outline: "none"
            }}
            type="submit"
          >
            SEND
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
