import './App.css';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import OfficersList from './components/OfficersList';
import OurTeam from './components/OurTeam';
import PhotoGallery from './components/PhotoGallery';
import Registration from './components/Registration';
import WhoWeAre from './components/WhoWeAre';
import Login from './components/Login';
import MemberShip from './components/MemberShip';
import Admin from './components/Admin';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize, false);
    }
  }, []);

  const options = [
    { comp: AboutUs, heading: "ABOUT US", topic: "ABOUT US", name: "AboutUs" },
    { comp: ContactUs, heading: "CONTACT US", topic: "CONTACT US", name: "ContactUs" },
    { comp: Home, heading: "", topic: "", name: "Home" },
    { comp: Login, heading: "LOGIN", topic: "LOGIN", name: "Login" },
    { comp: PhotoGallery, heading: "PHOTO GALLERY", topic: "PHOTO GALLERY", name: "PhotoGallery" },
    { comp: Registration, heading: "REGISTRATION", topic: "COMPLAINT REGISTRATION", name: "Registration" },
    { comp: WhoWeAre, heading: "WHO WE ARE", topic: "WHO WE ARE", name: "WhoWeAre" },
    { comp: MemberShip, heading: "MEMBERSHIP", topic: "MemberShip", name: "MemberShip" },
    { comp: OfficersList, heading: "OFFICERS LIST", topic: "OFFICERS LIST", name: "OfficersList" },
    { comp: OurTeam, heading: "Our Team", topic: "OurTeam", name: "OurTeam" }
  ];

  const [ActiveComp, setActiveComp] = useState({ comp: Home, heading: "", topic: "", name: "Home" });

  function setActiveCompHandler(compName) {
    const arr = options.filter(ele => compName === ele.name);
    setActiveComp({ ...arr[0] });
  }

  return (
    <div>
            <Header setActiveCompHandler={setActiveCompHandler} heading={ActiveComp.heading} topic={ActiveComp.topic} options={options} />
      
      <Routes>
          <Route path="/" element={
             <div className="App" style={{ marginTop: "50px", marginBottom: "50px", display: "flex", width: dimensions.width < 500 ? "100vw" : "95vw", margin: "auto" }}>
              {ActiveComp.comp === Home ? <Home dimensions={dimensions} /> : <></>}
              {ActiveComp.comp === AboutUs ? <AboutUs dimensions={dimensions} /> : <></>}
              {ActiveComp.comp === ContactUs ? <ContactUs dimensions={dimensions} /> : <></>}
              {ActiveComp.comp === Login ? <Login dimensions={dimensions} /> : <></>}
              {ActiveComp.comp === PhotoGallery ? <PhotoGallery dimensions={dimensions} /> : <></>}
              {ActiveComp.comp === Registration ? <Registration dimensions={dimensions} /> : <></>}
              {ActiveComp.comp === WhoWeAre ? <WhoWeAre dimensions={dimensions} /> : <></>}
              {ActiveComp.comp === OfficersList ? <OfficersList dimensions={dimensions} /> : <></>}
              {ActiveComp.comp === MemberShip ? <MemberShip dimensions={dimensions} /> : <></>}
              {ActiveComp.comp === OurTeam ? <OurTeam dimensions={dimensions} /> : <></>}
            </div>        
        } />
        
        <Route path="/login" element={<Login dimensions={dimensions} />} />
        <Route path="/admin" element={<Admin dimensions={dimensions} />} />
      </Routes>


      <Footer setActiveCompHandler={setActiveCompHandler} />
            <div style={{ boxShadow: "0px 0px 10px black", padding: "20px", fontSize: "12px", backgroundColor: "#FD2100", textAlign: "center", color: "gray", width: "100%" }}>
              <span style={{ color: "white" }}> Copyright © 2024 Human Development and Research Services (HDRS)..   All Rights Reserved | | Design & Developed by:</span>
              <span style={{ color: "white" }}> Kirti</span>
            </div>
        </div>
    
  );
}

export default App;
