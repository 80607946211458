import React from 'react'
import Pic from "../images/photo.png"

const AboutUs = ({dimensions}) => {
  return (
    <div className='aboutus' style={{display:"flex",justifyContent:"center",alignContent:"center",flexDirection:"column",gap:"2rem",padding:"2rem 9%",width:"100%"}}>
     
      <main>
        <section style={{backgroundColor:"white",border:"2px solid blue",boxShadow:"0px 0px 10px blue",borderRadius:"20px",display:"flex",flexWrap:"wrap",justifyContent:"space-around",width:"100%"}}>
            <div style={{backgroundColor:"white"}}>
                <img style={{width:"100%",marginTop:"20px"}} src={Pic} alt=".." />
                <div style={{fontSize:"20px",fontWeight:700,textAlign:"center",display:"flex",flexDirection:"column",color:"black"}}>
                    <span>Mr. Kuldeep Singh</span>
                    <span>Cheif Director</span>
                </div>
            </div>

            <div style={{textAlign:"left",width:"500px",paddingRight:dimensions.width<=500?"20px":"0px"}}>
                <h3 style={{fontSize:"25px",paddingTop:"50px", paddingLeft:"20px", textDecoration:"underline",color:"blue"}}>About Us</h3>
                <div style={{padding:"20px",}}>
                  <p>Human Development and  Research Services (HDRS).</p>
                  <p>We are team of intellectual full of pain with social injustice and looking forward to hear and share the voice of suppressed and dipress classes in the society who eagerly looking and waiting for a helping hand in violation of their human rights. Those who are capable socially, economically, educationally and financially, usually undermine the rights of weaker. Not in all cases, but most of the time the victims keep silent either due to ignorance or due to threats of dominance. In all such situations National Human Development and  Research Services (HDRS) is always standing by the side of every victims.</p>
                  <p>We are having a dedicated team and reaching door to door to solve the panics. We assure all needy and trying to overcome the problem within our capabilities with the help of constitutional rights.</p>
                </div>
                </div>
        </section>
        <section style={{padding:"2rem 3%",border:"2px solid red",borderRadius:"20px",marginTop:"2rem"}}>
            <h2 style={{color:"red",fontWeight:"600"}}>मानव विकास एवं अनुसंधान सेवा</h2>
            <ol style={{textAlign:"left",paddingRight:dimensions.width<=500?"20px":"0px"}}>
                <li>
                महिलाओं के विभिन्न सेल का निर्माण कर महिलाओं के विकास में सरकारी क्रियान्वयन को आगे बढ़ाना।
                </li>
                <li>
                जिला जन्म जात मनोविकार ग्रस्त बच्चों की रोकथाम के सुदूर अंचलों में गर्भवती महिलाओं को स्वस्थ में हर पल जागरूक रहने के लिए संगठन प्रयासरत रहेगा।

                </li>
                <li>
                शारीरिक व मानसिक रूप से व्यक्तियों को आत्मनिर्भर बनाने के लिए राष्ट्रीय कार्यक्रम में सहयोग प्रदान करना व पीड़ित व्यक्तियों की समस्याओं को संकलित कर सरकार को अवगत कराना।
                </li>
                <li>
                बाल अपराध की प्रवृत्तियों को पता लगाना एवं उनके निदान ओं के लिए रचनात्मक प्रयास कर केंद्र और राज्य सरकारों को समय-समय पर जानकारी देना।
                </li>
                <li>
                विद्यार्थियों में पनप रही मादक पदार्थों के सेवन की प्रवृति पर रोकथाम के लिए प्रेरणादायक स्वास्थ्य जीवन जीने की कला संबंधित कार्यक्रम संचालित करना एवं उनके राष्ट्र के लिए जागरूक करना।
                </li>
                <li>
                सरकार द्वारा चलाए जा रहे साक्षरता मिशन के अंतर्गत ग्राम पंचायत जनपद पंचायत जिला पंचायत एवं नगर पंचायतों के सहयोग से संस्था स्वयं के विद्यालय स्थापित कर सुदूर ग्रामीण क्षेत्रों में निर्धन व गरीब छात्रों को शिक्षा की व्यवस्था सरकार से निवेदन करना।
                </li>
                <li>
                समाज में पनप रही बुराइयों के उन्मूलन के लिए योग साधना केंद्र चलाकर मानव को स्वास्थ्य जीवन जीने का मार्ग प्रसस्त करने पर तथा नशाखोरी घूसखोरी में फंसे लोगों को मुक्त कराना।
                </li>
                <li>
                बाल श्रम रोकने के लिए हर संभव प्रयास करना।
                </li>
                <li>
                कालाबाजारी रोकने के लिए हर संभव प्रयास करना।
                </li>
                <li>
                पुलिस से प्रताड़ित लोगों को न्याय दिलाने के लिए विभाग के वरिष्ठ अधिकारियों से 15 दिनों में शिकायतों के निस्तारण की जानकारी मांगेंगे गंदी विचारधारा रखने वाले पुलिस अधिकारियों की कार्यप्रणाली को पुलिस मुख्यालय केंद्रीय गृह मंत्रालय गृह राज मंत्रालय से अवगत कराना और कार्यवाही निवेदन कराना।
                </li>
                <li>
                शासकीय अर्ध शासकीय एवं निजी उद्योगों में सेवारत अधिकारियों के कल्याण शर्तों के विपरीत व्यापक शोषण योग प्रार्थना की सूक्ष्म विवेचना कर वास्तविक जानकारी संकलित कर सरकार को अवगत कराना।
                </li>
                <li>
                अकाल बाढ़ भूकंप व अन्य प्राकृतिक अपराध तथा असामाजिक दुर्घटनाओं से प्रभावित व्यक्तियों के कल्याण के लिए सामाजिक सद्भावना बनाना तथा पीड़ित परिवार की सहायता करना और शक्ति काल में सरकार को वंचित जानकारी उपलब्ध कराते हुए सहयोग प्रदान करना।
                </li>
                <li>
                स्वास्थ्य के क्षेत्र में आने वाली समस्त योजनाओं जैसे एड्स कैंसर टीवी हेपेटाइटिस मधुमेह आदि जानलेवा बीमारियों की पहचान व रोकथाम जागरूकता शिविरों के माध्यम से लोगों को जागरूक करना एवं जनता को बीमारी से बचने के लिए हर संभव प्रयास करना।
                </li>
                <li>
                संस्था द्वारा अपनी समस्त योजनाओं कार्यक्रमों व उद्देश्य प्रचार-प्रसार समस्त माध्यमों से जैसे न्यूज़ विज्ञापन पंपलेट लेख पोस्टर बैनर होर्डिंग रेडियो टेलीविजन आदि के माध्यम से कराते हुए जनता तक पहुंचाने का प्रयास करना जिससे क्षेत्रीय जनता लाभान्वित हो सके।
                </li>
                <li>
                सूचना प्रसारण मंत्रालय एवं जनसंपर्क विभाग भारत सरकार राज्य सरकार से पत्रकारों से प्राप्त होने वाली सुविधाओं के क्रियान्वयन में बरती जाने वाली लापरवाही ऊपर सरकार को अवगत कराना तथा पत्रकार सेलो का गठन करना।
                </li>
                <li>
                जिला योजना समिति ओं नगरी प्रशासन द्वारा निर्धारित योजनाओं से लेकर कार्य कराना तथा बिजली सड़क पानी और शिक्षा के क्षेत्र में जिला नमस्कार शिक्षा अधिकारियों और कलेक्ट्रेट के माध्यम से निजी स्कूलों के द्वारा होने वाली उत्पीड़न के लिए सरकार को अवगत कराना और कार्यवाही हेतु निवेदन करना।
                </li>
                <li>

                संगठन के सदस्यों द्वारा शोषण के खिलाफ जनता को जागरूक करने का प्रमुख कार्य किया जाएगा तथा मानवीय संवेदनाएं की लापरवाही ओं को सरकार से अवगत कराना न्याय ना मिलने की दिशा में माननीय न्यायालय में अपील कराना।
                </li>
                <li>
                संगठन द्वारा किए गए कार्यों का लेखा-जोखा वर्ष में एक बार अधिवेशन के माध्यम से जनता के सामने रखना।
                </li>
                <li>
                प्रधानमंत्री संसद विधायक पार्षद मुख्यमंत्री राज्यपाल की निधि से आम जनता को सहयोग दिलाने का प्रयास कर निवेदन करना और इन निधियों की राशि का दुरुपयोग करने वाले पर कार्यवाही के लिए माननीय न्यायालय में जाकर जनहित रहस्य की जांच करवाने का प्रयास कराना।
                </li>
                <li>
                अनुसूचित जाति जनजाति पिछड़ा वर्ग आदिवासी के सरकारी को सरकार से लेकर किन वर्गों के विकास के लिए कार्य करना।
                </li>
                <li>
                माननीय सर्वोच्च न्यायालय द्वारा सिटीजन चार्ट के तहत दिए गए निर्देशों के पालन नहीं करने वाले के विरुद्ध माननीय न्यायालय और सरकार को अवगत कराना माननीय सर्वोच्च न्यायालय द्वारा मानवीय संवेदना के प्रति दिए गए दिशा-निर्देश के प्रचार प्रसार को आगे बढ़ाना।
                </li>
            </ol>
        </section>
      </main>
    </div>
  )
}

export default AboutUs
