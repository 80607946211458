import React from 'react'

const WhoWeAre = () => {
  return (
    <div style={{textAlign:"left"}}>
      <p>
      <b>NATIONAL HUMAN RIGHTS JUSTICE COMMISSION</b> autonomously as leaders in Registered under Indian Trust Act, 1882 and MSME (UDYOG ADHAR) Regd. No UDYAM-U P-50-0046115 (Ministry of Micro Small and Medium Enterprises, Govt. of India). National Human Rights Justice Commission Registered with NITI AYOG having Unique ID Of Vo/NGO U P/2022/0309345
      </p>
      <p>
      NATIONAL HUMAN RIGHTS JUSTICE COMMISSION is a service organization that supports, encourages, and facilitates the work of others with and within the United Nations system and regional human rights systems. We work at regional and national levels. We seek to promote the development, strengthening, effective use and implementation of international and regional laws and mechanisms for the protection and promotion of human rights. NHRJC is a non-profit organization recognized under the Indian laws. We are funded by Government agencies and private foundations. However, we remain independent from our donors both in matters of policy and implementation of our Programmes. “Human Rights organization that uses education and popular culture to promote values of dignity, equality and justice for all. We encourage individuals and communities to get involved in promoting Social Harmony and building a culture of Human Rights. ”AIMS & OBJECTIVES
      </p>
      <p>
      To promote and protect the constitutional fundamental rights of common people.
      </p>
      <p>
      To get public help in solving social problems. To promote respect for all without discrimination. To promote social culture and character. To promote a peaceful environment for all. To encourage the citizens of India to abide by their rights and duties.
      </p>
      <p>
      To help remove social evils especially from the weaker sections of the society.
      </p>
      <p>
      To help and assist by this society directly or through the fund of prime Minister and / or Chief Ministers to the victim of flood, Famine, fire, earthquake, cyclone, tsunami, accident or any other natural and man-made calamities.
      </p>
      <p>
      To establish, manage, control and run the medical hospital, clinics, dispensaries, mobile dispensaries / hospital, medical check-up center’s, and to provide medical treatment to the common people, Needy patients and poor families.
      </p>
      <p>
      To run free or low cost educational institutions for the poor people. To run projects to promote nutrition, sanitation, and health and community development in underdeveloped areas.
      </p>
      <p>
      To run Old Age Homes, Orphanages, Widows Homes, Lunatic Asylums, Maternity Homes, Children Welfare Centers.
      </p>
      <p>
      To distribute awards, prize, scholarship, evaluation certificates / testimonials, to the brilliant, brave and eligible, students/persons.</p>
      <p>
      To publish books, charts, illustration, periodicals, website and publications to promote the HRCP society.
    </p>
    <p>
    To provide help to physically and mentally handicapped people.
    </p>
    <p>
    To run medical laboratories, test and research centers for the poor people and needy patients.
    </p>
    <p>
    To run community help centers like community halls, marriage halls, Dharam-Shallas, Piaos, Sulabh Shauchalyas, Aaganwaris, Balwaris, Drama troupes, Music Groups, Folk Dance Groups and such Social and Cultural Activities.
    </p>
    <p>
    To work against such social evils like Child the Marriage, Dowry system, Child Labour, Bonded Labour etc. and wastage of money.
    </p>
    <p>
    To make adequate arrangements and start, run the rehabilitation centers for destitute, widows, old man, poor, beggars, handicapped and for mentally retarded persons for their social and civil upliftment.
    </p>
    <p>
    To work to eradicate social evils like Drug abuse, Alcohol, Cigarette, Bidi consumption.
    </p>
    <p>
    To organize awareness camps against such evils by all means possible.
    </p>
    <p>
    To prepare the awareness generation kits, posters, banners, audio-video, cassettes, documentary films etc. regarding various social issues and to fulfill the aims and objectives of the society.
    </p>
    <p>
    To approach the concerned authorities regarding the solution of the people’s problems in different societies.
    </p>
    <p>
    To arrange, organize and establish plant nursery, firm, for plantation especially for Ayurvedic Plants, Flowers, Fruits, to start green revolution to balance the environment and controlling pollution.
    </p>
    <p>
    To maintain Indian Art, Cultural Activities, Educational Programmes, providing training for music, dance, art school for the interested common people including Quiz contest etc.
    </p>
    <p>To organize and arrange for Blood donation camp, Eye donation and Eye operation camp mobile, Hospitals in vehicles and to provide ambulance facilities for the poor and needy patients.</p>
    <p>
    To promote awareness of family planning to control the over population in our societies as per Govt. Policies.
    </p>
    <p>
    To educate the public against water, soil and air pollution and to promote Water Harvesting and Conservation.
    </p>
    <p>
    To raise the nursery and plantation lands seeking permission from Govt. concerned authorities and/or from public.
    </p>
    <p>
    To maintain neatness and cleanness in different residential areas, Slum areas, in Jhuggi and in slums and to promote awareness of healthy environment for the welfare of the common people.
    </p>
    <p>
    To arrange for charity shows and cultural programmes for the entertainment of public as well as to raise funds for the society.
    </p>
    
    <h2>WHAT WE DO</h2>
    <div>
    A guide to what the Human Rights & Citizens Power does, and how it does it.

Influencing the development of the law and government policy<br/>
Promoting good social practices<br/>
Campaigns, events and communications<br/>
Fostering better relations among all sections of society<br/>
Developing understanding, peace and harmony<br/>
OUR WORK CULTURETo fulfill its comprehensive human rights mandate, NHRJC speaks out objectively in the face of human rights violations everywhere. It provides a forum for identifying, highlighting and developing responses to today’s human rights challenges, and acts as the principal focal point of human rights research, education, public information, and human rights advocacy activities in the United Nations system.CURRENT FOCUS<br/>
NHRJC Is Currently Working Mainly Focusing On<br/>

Empowering of Human Rights, Culture, Health, Education, Socio-Economic, Development, Language, Land, Water, Forest and Biodiversity.<br/>
Educating for Conflict Resolution, Negotiation and Peace building capacities.<br/>
Empowering the poor for Financial Freedom for self and country.<br/>
    </div>
    </div>
  )
}

export default WhoWeAre
