import React, { useState } from 'react';
import ContactData from './ContactData';
import ComplaintData from './ComplaintData';
import RegistrationData from './RegistrationData';
import './Admin.css';

const Admin = () => {
  const [activeComponent, setActiveComponent] = useState('contact');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'contact':
        return <ContactData />;
      case 'complaint':
        return <ComplaintData />;
      case 'registration':
        return <RegistrationData />;
      default:
        return <ContactData />;
    }
  };

  return (
    <div className="admin-page">
      <div className="admin-buttons">
        <button onClick={() => setActiveComponent('contact')}>Contact Data</button>
        <button onClick={() => setActiveComponent('complaint')}>Complaint Data</button>
        <button onClick={() => setActiveComponent('registration')}>MemberShip Data</button>
      </div>
      <div className="admin-content">
        {renderComponent()}
      </div>
    </div>
  );
};

export default Admin;
