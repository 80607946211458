import React, { useEffect, useState } from 'react'
import Logo from "../images/logo (2).jpg"
import burger from "../images/burger.png"
import './Header.css'
import phone from "../images/phone.png"
import email from "../images/email.png"
import home from "../images/home.png"


const Header = ({ heading, topic, setActiveCompHandler }) => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const [showHam, setShowHam] = useState(false);
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  console.log(dimensions);
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    if (dimensions.width >= 540) {
      setShowHam(false);
    }
    else {
      setShowHam(true)
    }
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, [dimensions]);

  let margin_left = dimensions.width <= 900 ? "-10vw" : "10vw";

  return (
    <div>
      {/* <div style={{width:"100%", background:"#FD2100",height:"30px",color:"white",fontWeight:"400"}}>
            <h6 style={{paddingLeft:"15%",paddingTop:"1px"}}> <img style={{height:"20px",width:"20px"}} src={phone} /> +917082179144, <img style={{height:"15px",width:"22px"}} src={email} />  humandevelopmentresearchservic@gmail.com </h6>
        </div> */}
       <div className="top" style={{display:"flex",flexDirection:"column",gap:"0px"}}>
      <div className='head'>
        <div className='phone' style={{marginTop:"2px"}}> 
          <h6><img style={{ height: "20px", width: "20px" }} src={phone} /> +917082179144</h6>
        </div>
        <div className='email'>
          <h6><img style={{ height: "17px", width: "22px" }} src={email} /> humandevelopmentresearchservic@gmail.com </h6>
        </div>
      </div>
      <div style={{ color: "white", width: "100%", alignItems: "center", background: "#0100B6", height: "70px", display: "flex", justifyContent: "space-around", gap: "5vw" }}>
        <img style={{ marginLeft: margin_left, cursor: "pointer", height: "60px" }} onClick={() => { setActiveCompHandler("Home") }} src={Logo} alt=".." />
        {dimensions.width <= 900 ? <div >
          <img
            style={{ width: "30px", border: "1px solid white", borderRadius: "2px", padding: ".5px", backgroundColor: "transparent" }} src={burger} alt=".."
            onClick={() => { setToggle(toggle ? false : true) }}
          />
          {toggle ?
            <div className='nav_links' style={{ color: "black", position: "absolute", width: "200px", backgroundColor: "white", right: "0px", display: "flex", flexDirection: "column", zIndex: "2", gap: "10px", top: "130px", padding: "20px" }}>
              <span className={active == 'Home' ? 'active' : ''} style={{ paddingBottom: "10px", borderBottom: "1px solid black", cursor: "pointer" }} onClick={() => { setActiveCompHandler("Home"); setActive('Home') }}>Home</span>
              <span className={active == 'AboutUs' ? 'active' : ''} style={{ paddingBottom: "10px", borderBottom: "1px solid black", cursor: "pointer" }} onClick={() => { setActiveCompHandler("AboutUs"); setActive('AboutUs') }}>About Us</span>
              <span className={active == 'Registration' ? 'active' : ''} style={{ paddingBottom: "10px", borderBottom: "1px solid black", cursor: "pointer" }} onClick={() => { setActiveCompHandler("Registration"); setActive('Registration') }}>Registration</span>
              <span className={active == 'MemberShip' ? 'active' : ''} style={{ paddingBottom: "10px", borderBottom: "1px solid black", cursor: "pointer" }} onClick={() => { setActiveCompHandler("MemberShip"); setActive('MemberShip') }}>MemberShip</span>
              <span className={active == 'ContactUs' ? 'active' : ''} style={{ paddingBottom: "10px", borderBottom: "1px solid black", cursor: "pointer" }} onClick={() => { setActiveCompHandler("ContactUs"); setActive('ContactUs') }}>Contact Us</span>
              <span className={active == 'PhotoGallery' ? 'active' : ''} style={{ paddingBottom: "10px", borderBottom: "1px solid black", cursor: "pointer" }} onClick={() => { setActiveCompHandler("PhotoGallery"); setActive('PhotoGallery') }}>Photo Gallery</span>
              <span className={active == 'OfficersList' ? 'active' : ''} style={{ paddingBottom: "10px", borderBottom: "1px solid black", cursor: "pointer" }} onClick={() => { setActiveCompHandler("OfficersList"); setActive('OfficersList') }}>Officers List</span>
              <span className={active == 'OurTeam' ? 'active' : ''} style={{ paddingBottom: "10px", borderBottom: "1px solid black", cursor: "pointer" }} onClick={() => { setActiveCompHandler("OurTeam"); setActive('OurTeam') }}>Our Team</span>

            </div>
            : <></>}
        </div>
          :
          <div className='nav_links' style={{ width: "70vw", display: "flex", justifyContent: "space-around", cursor: "pointer" }}>
            <span className={active == 'Home' ? 'active' : ''} onClick={() => { setActiveCompHandler("Home"); setActive('Home') }}>Home</span>
            <span className={active == 'AboutUs' ? 'active' : ''} onClick={() => { setActiveCompHandler("AboutUs"); setActive('AboutUs') }}>About Us</span>
            <span className={active == 'Registration' ? 'active' : ''} onClick={() => { setActiveCompHandler("Registration"); setActive('Registration') }}>Registration</span>
            <span className={active == 'MemberShip' ? 'active' : ''} onClick={() => { setActiveCompHandler("MemberShip"); setActive('MemberShip') }}>MemberShip</span>
            <span className={active == 'ContactUs' ? 'active' : ''} onClick={() => { setActiveCompHandler("ContactUs"); setActive('ContactUs') }}>Contact Us</span>
            <span className={active == 'PhotoGallery' ? 'active' : ''} onClick={() => { setActiveCompHandler("PhotoGallery"); setActive('PhotoGallery') }}>Photo Gallery</span>
            <span className={active == 'OfficersList' ? 'active' : ''} onClick={() => { setActiveCompHandler("OfficersList"); setActive('OfficersList') }}>Officers List</span>
            <span className={active == 'OurTeam' ? 'active' : ''} onClick={() => { setActiveCompHandler("OurTeam"); setActive('OurTeam') }}>Our Team</span>

          </div>}
      </div>
      </div> 

      {heading === "" ? <></> :
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", background: "white", height: "100px", padding: "1rem 9%" }}>

          <h5 style={{ padding: "5px 5%", backgroundColor: "red", display: "flex", alignItems: "center", borderRadius: "20px", color: "white" }}>
            <img src={home} className={active == 'Home' ? 'active' : ''} onClick={() => { setActiveCompHandler("Home"); setActive('Home') }} style={{ width: '25px', marginRight: "1px", cursor: "pointer" }} alt="" />
            {" >   "}{topic}</h5>
        </div>}

    </div>

  )
}

export default Header
