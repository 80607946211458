import React from 'react'

const OfficersList = () => {
  return (
    <div className='officerslist' style={{width:"100%",padding:"20px 5%",textAlign:'left',fontSize:"18px"}}>
      <div style={{border:"2px solid red",borderRadius:"20px",padding:"3%"}}>
        <h3 style={{fontWeight:"600",color:"red"}}>Officers List:-</h3>
        <p style={{width:"100%"}}>Center/State/Division/Zone/District/Tahseel</p>
        1. Director -1 <br />
        2. Deputy Director <br />
        3. General Secretary-1<br/>
        4. Secretary- (upto -4)<br/>
        5. Organization Secretary-1<br/>
        6. Incharge -1<br/>
        7. Program Secretary-1<br/>
        8. Media Incharge-1<br/>
        9. Welfare Secretary -1<br/>
        10. Law Secretary-1<br/>
        11. Cashier-1<br/>
        12. Spokesman-1<br/>
        13. Auditor-1<br/>
        14. Legal Advisor-(upto-4)<br/>
        15. Patron- (upto-4)<br/>
        16. Member- unlimited<br/>
        17. Other officers of the National Committee<br/>
        <br/>
        इसी प्रकार 👇👇👇<br/>
        1.) महिला संघ ( women Forum)<br/>
        2.) यूवा संघ (Yuth Forum)<br/>
        3.)शिक्षक संघ ( Teacher Forum)<br/>
        4.) चिकित्सक संघ (Doctor Forum)<br/>
        5.) न्याय संघ ( Legal Forum)<br/>
        6.) किसान संघ ( Farmer Forum)<br/>
        7.) Media Forum<br/>
    </div>
    </div>
  )
}

export default OfficersList
