import React, { useEffect, useState } from 'react';
import { db } from "../firebase";
import { collection, getDocs } from 'firebase/firestore';
import './ContactData.css';

const ContactData = () => {
    const [data, setData] = useState([]);

    const getData = async () => {
        const valRef = collection(db, 'Contactus');
        const dataDb = await getDocs(valRef);
        const allData = dataDb.docs.map(val => ({ ...val.data(), id: val.id }));
        
        setData(allData);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="contact-data-container">
            <h1>Contact Data</h1>
            <div className="table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Subject</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(value => (
                            <tr key={value.id}>
                                <td>{value.Name}</td>
                                <td>{value.Email}</td>
                                <td>{value.Phone}</td>
                                <td>{value.Subject}</td>
                                <td>{value.Message}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ContactData;
