import React from 'react'
import "./footer.css"
import { NavLink } from 'react-router-dom'
const Footer = ({setActiveCompHandler}) => {
  return (
    <div className='footer'>
      <h2>About Us</h2>
      <p>We are team of intellectual full of pain with social injustice and looking forward to hear and share the voice of suppressed and depress classes in the society who eagerly looking and waiting for a helping hand in violation of their human rights. Those who are capable socially, economically, educationally and financially, usually undermine the rights of weaker.</p>
      <h2>FOLLOW US</h2>
      <p>
       <a href="https://www.instagram.com/p/C9Cd-HDz5_i/?igsh=MXZ4OWlodjZrZzFvbw=="> <img style={{width:"20px",borderRadius:"50%"}} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"} alt="insta"/></a>
       {/* <a href="" > <img style={{width:"20px",borderRadius:"50%"}} src={"https://i.pinimg.com/474x/7d/44/d5/7d44d55ead7dda48bd95632d92fb259d.jpg"} alt="twitter"/></a> */}
       <a href="https://www.facebook.com/share/LQsJVYdVqT5FVYBD/?mibextid=xfxF2i"> <img style={{width:"20px",borderRadius:"50%"}} src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjvzC_QRv6moAhgNb5C6e3yicKgFND1g2RwA&s"} alt="fb"/></a>
       <a href="https://youtu.be/ZrP2BtrpSN0?si=c-cBVmG6Lv2LVszJ"><img style={{width:"20px",borderRadius:"50%"}} src={"https://cdn3.iconfinder.com/data/icons/social-network-30/512/social-06-512.png"} alt="youtube"/></a>
      </p>

      <div className='links'>
        <div className='links-menu'>
        <h5>NAVIGATION MENU</h5>
        <ul style={{listStyle:"none",padding:0}} >
            <li onClick={()=>{setActiveCompHandler('Home')}}>Home</li>
            <li onClick={()=>{setActiveCompHandler('AboutUs')}}>About Us</li>
            <li onClick={()=>{setActiveCompHandler('ContactUs')}}>Contact Us</li>
        </ul>
        </div>
        <div className='links-menu'>
        <h5>QUICK LINKS</h5>
            <ul style={{listStyle:"none",padding:0}}>
                <li onClick={()=>{setActiveCompHandler('OfficersList')}}>Our Team</li>
                <li onClick={()=>{setActiveCompHandler('Registration')}}>Registration</li>
                <li onClick={()=>{setActiveCompHandler('PhotoGallery')}}>Photo Gallery</li>
                <NavLink to="/login" ><li>Admin Panel</li></NavLink>
            </ul>
        </div>
        <div className='links-menu'>
         <h5>CONTACT INFO</h5>
        <ul style={{listStyle:"none",padding:0}}>
            <li>C Block flat number 602 Sun City district Rohtak, state Haryana, BHARAT -124001</li>
            <li>7082179144, 9053100144</li>
            <li>humandevelopmentresearchservic@gmail.com</li>
        </ul>
      </div> 
      </div>
      
    </div>
  )
}

export default Footer
