import React, { useEffect, useState } from 'react';
import { db } from "../firebase";
import { collection, getDocs } from 'firebase/firestore';
import './RegistrationData.css';

const RegistrationData = () => {
    const [data, setData] = useState([]);

    const getData = async () => {
        const valRef = collection(db, 'memberships');
        const dataDb = await getDocs(valRef);
        const allData = dataDb.docs.map(val => ({ ...val.data(), id: val.id }));
        setData(allData);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="registration-data-container">
            <h1>Membership Data</h1>
            <div className="table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th>Profile Photo</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>DOB</th>
                            <th>Gender</th>
                            <th>Address</th>
                            <th>Qualification</th>
                            <th>Receipt</th>
                            <th>Document</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(value => (
                            <tr key={value.id}>
                                <td><img src={value.photo} alt="" style={{ height: "50px", width: "50px" }} /></td>
                                <td>{value.fullName}</td>
                                <td>{value.email}</td>
                                <td>{value.mobileNumber}</td>
                                <td>{value.dob}</td>
                                <td>{value.gender}</td>
                                <td>{value.address}</td>
                                <td>{value.qualification}</td>
                                <td><button><a href={value.receipt} target="_blank" rel="noopener noreferrer">Check</a></button></td>
                                <td><button><a href={value.documentFront} target="_blank" rel="noopener noreferrer">Check</a></button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RegistrationData;
