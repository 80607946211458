import React from 'react';
import './OurTeam.css';

const tempArr = [
    {
        "S.No.": 1,
        Name: "KULDEEP SINGH",
        Designation: "Chief Director",
        Address: "Haryana (India)"
    },
    {
        "S.No.": 2,
        Name: "PARDEEP SINGH",
        Designation: "National Deputy director",
        Address: "Haryana (India)"
    },
    {
        "S.No.": 3,
        Name: "ANUJ KUMAR",
        Designation: "National General Secretary",
        Address: "Haryana (India)"
    },
    {
        "S.No.": 4,
        Name: "KRISHN DEV",
        Designation: "National Treasurer",
        Address: "Haryana (India)"
    },
    {
        "S.No.": 5,
        Name: "KIRTI GARG",
        Designation: "National secretary of head office",
        Address: "Delhi (India)"
    },
    {
        "S.No.": 6,
        Name: "DR PANKAJ SHARMA",
        Designation: "National human development officer",
        Address: "RAJSTHAN (India)"
    },
    {
        "S.No.": 7,
        Name: "RAKHI SHOKEEN",
        Designation: "Haryana state women development officer",
        Address: "Haryana (India)"
    }
];

const OurTeam = () => {
    return (
        <div className="our-team-container">
            <div className="team-header">
                <h2>National Members</h2>
            </div>
            <div className="team-grid team-header-row">
                <span className="team-cell header-cell">Sl. No.</span>
                <span className="team-cell header-cell">Name</span>
                <span className="team-cell header-cell">Designation</span>
                <span className="team-cell header-cell">Address</span>
            </div>
            {tempArr.map((ele, index) => (
                <div className="team-grid team-row" key={index}>
                    <span className="team-cell">{ele['S.No.']}</span>
                    <span className="team-cell">{ele.Name}</span>
                    <span className="team-cell">{ele.Designation}</span>
                    <span className="team-cell">{ele.Address}</span>
                </div>
            ))}
        </div>
    );
}

export default OurTeam;
