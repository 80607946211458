import React, { useState, useEffect } from 'react'
import './Home.css';
import img1 from "../images/IMG1.jpg"
import img2 from "../images/IMG2.jpg"
import c1 from "../images/IMG5.jpg"
import c2 from "../images/IMG6.jpg"
import c3 from "../images/IMG7.jpg"
import c4 from "../images/IMG8.jpg"
import c5 from "../images/IMG9.jpg"
import Pic from "../images/photo.png";
import donateQr from '../images/donateQr.png'
import ResponsiveCarousel from './ResponsiveCarousel';

const Home = () => {
  let imgArr = [c1, c2, c3, c4, c5];
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const [showButton, setShowButton] = useState(true); // Button visibility state
  const [showPopup, setShowPopup] = useState(false);  // Popup visibility state

  useEffect(() => {
   
    const interval = setInterval(() => {
      setShowButton(prevShow => !prevShow);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, [dimensions]);

  const handleClick = () => {
    setShowPopup(true); 
  };

  const handleClosePopup = () => {
    setShowPopup(false); 
  };
  return (
    <div className="home" >
      
      <div style={{ fontSize: "20px", color: "white" }}>
        <marquee style={{ color: "red", fontWeight: "600" }}>मानव विकास एवं अनुसंधान सेवा से जुड़ने के लिए सम्पर्क करें -7082179144, 9053100144, 9813430698, 9350198448</marquee>


      
        <div style={{width:"100%",padding:"2rem 9%"}}>
        <ResponsiveCarousel/>
        </div>



        <div className='box'>
          <div className='boxes'>
            <img src={img1} alt=".." />
          </div>
          <div className='boxes'>
            <img src={img2} alt=".." />
          </div>
        </div>

        <div style={{ border: "2px solid red",borderRadius:"20px", padding: "1rem 2%", margin: "3rem 0" }}>
          <h2 style={{ color: "red", marginTop: "20px", marginBottom: "20px" }}>Cheif Director</h2>
          <div style={{ textAlign: "center", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            <div style={{ textAlign: "center", margin: "auto", boxShadow: "0px 0px 10px #000000", padding: "10px 20px", width: "300px", backgroundColor: "white" }}>

              <img src={Pic} alt="..." style={{ width: "90%" }} />
              <p style={{ display: "flex", flexDirection: "column", marginTop: "20px", color: "red" }}>
                <span>Mr. Kuldeep Singh</span>
                
              </p>
            </div>

            <div style={{ color: "black", textAlign: "justify", padding: "20px", width: "500px", margin: "auto", backgroundColor: "white" }}>
              <p>
                We are team of intellectual full of pain with social injustice and looking forward to hear and share the voice of suppressed and dipress classes in the society who eagerly looking and waiting for a helping hand in violation of their human rights. Those who are capable socially, economically, educationally and financially, usually undermine the rights of weaker. Not in all cases, but most of the time the victims keep silent either due to ignorance or due to threats of dominance. In all such situations Human Development and Research Services (HDRS). is always standing by the side of every victims.
              </p>
              <p>
                We are having a dedicated team and reaching door to door to solve the panics. We assure all needy and trying to overcome the problem within our capabilities with the help of constitutional rights.
              </p>
            </div>
          </div>
        </div>

        <div style={{ textAlign: "left", color: "black", border: "2px solid blue",borderRadius:"20px", padding: "10px 20px" }}>
          <h2 style={{ marginTop: "20px", marginBottom: "40px",color:"Blue",fontWeight:"700" }}>WHAT ARE HUMAN RIGHTS?</h2>

          <p style={{fontWeight:"600"}}>Human rights belong to each and every one of us equally.</p>
          <p style={{fontWeight:"500"}}>Convention on the Rights of the Child.</p>
          <p>
            Human rights are standards that recognize and protect the dignity of all human beings. Human rights govern how individual human beings live in society and with each other, as well as their relationship with the State and the obligations that the State have towards them.
          </p>
          <p>
            Human rights law obliges governments to do some things, and prevents them from doing others. Individuals also have responsibilities: in using their human rights, they must respect the rights of others. No government, group or individual person has the right to do anything that violates another’s rights.
          </p>
          <p style={{fontWeight:"500"}}>
            Universality and inalienability
          </p>
          <p>
            Human rights are universal and inalienable. All people everywhere in the world are entitled to them. No one can voluntarily give them up. Nor can others take them away from him or her.

          </p>
          <p style={{fontWeight:"500"}}>Indivisibility</p>
          <p>Human rights are indivisible. Whether civil, political, economic, social or cultural in nature, they are all inherent to the dignity of every human person. Consequently, they all have equal status as rights. There is no such thing as a ‘small’ right. There is no hierarchy of human rights.</p>
          <p style={{fontWeight:"500"}}>
            Inter-dependence and inter-relatedness
          </p>

          <p>
            The realization of one right often depends, wholly or in part, upon the realization of others. For instance, the realization of the right to health may depend on the realization of the right to education or of the right to information.
          </p>
          <p style={{fontWeight:"500"}}>Equality and non-discrimination</p>
          <p>
            All individuals are equal as human beings and by virtue of the inherent dignity of each human person. All human beings are entitled to their human rights without discrimination of any kind, such as race, color, sex, ethnicity, age, language, religion, political or other opinion, national or social origin, disability, property, birth or other status as explained by the human rights treaty bodies
          </p>
          {/* <button style={{ padding: "10px 15px", color: "white", backgroundColor: "red", fontWeight: "700", borderRadius: "10px" }}>
            Read More
          </button> */}
        </div>

          
            <div style={{background:"red",margin:"2rem  0 .5rem 0",borderRadius:"20px"}}> <h2 style={{color:"white"}}>
          About Us
        </h2>
          </div>
       

        <div style={{ padding: "20px", color: "black", textAlign: "left", marginTop: "0px", border: "2px solid red",borderRadius:"20px" }}>
          <h3 >Human Development and  Research Services (HDRS).</h3>
          <p>
            We are team of intellectual full of pain with social injustice and looking forward to hear and share the voice of suppressed and depress classes in the society who eagerly looking and waiting for a helping hand in violation of their human rights. Those who are capable socially, economically, educationally and financially, usually undermine the rights of weaker. Not in all cases, but most of the time the victims keep silent either due to ignorance or due to threats of dominance. In all such situations Human Development and Research Services (HDRS). is always standing by the side of every victims.
          </p>
          <p>
            We are having a dedicated team and reaching door to door to solve the panics. We assure all needy and trying to overcome the problem within our capabilities with the help of constitutional rights.
          </p>
          


        </div>

        <div style={{ color: "black", border: "2px solid blue",borderRadius:"20px", textAlign: "left", padding: "20px", marginTop: "2rem", lineHeight: "39px", }}>
          <h2 style={{ color: "blue", fontWeight:"700" }}>हमारा उद्देश</h2>
          <ul>
            <li>देश के नागरिकों को एकजुट करना।</li>
            <li>देश के नागरिकों को उनके अधिकारों  के प्रति जागरूक करना एवं उनके मान-सम्मान की सुरक्षा करना।</li>
            <li>देश के नागरिको को शैक्षिक, सामाजिक रूप से शक्तिशाली बनाना।</li>
            <li>नागरिकों के  समस्त संवैधानिक अधिकारों के प्रति नागरिकों को जागरूक करना।</li>
            <li>शासन, प्रशासन एवं पुलिस के साथ सामांजस्य स्थापित कर अपराधों को रोकने में उनका सहयोग करना एवं पीडि़तों को न्याय दिलाना।</li>
            <li>समस्त जनसाधारण का सामाजिक नैतिक, चारित्रिक व बौद्धिक, अध्यात्मिक विकास करना एवं लोगों में देष प्रेम एवं वैचारिक सामन्जस्य का विकास करना।</li>
            <li>भारतीय संविधान में प्रदत्त सामाजिक न्याय, और आर्थिक उत्थान के लिये प्रयास करना।</li>
            <li>भ्रष्टाचार, आतंकवाद, महिला हिंसा ,बल श्रम ,शोषण , अव्यवस्था आदि वर्तमान ज्वलन्त समस्याओं के निवारण हेतु सरकारी अर्धसरकारी संस्थाओं के सहयोग से उन्हें दूर करने का प्रयास करना।</li>
            <li>
              मानवाधिकारों की जानकारी के प्रचार-प्रसार हेतु पत्र-पत्रिका का प्रकाशन करना एवं समय-समय पर सेमिनार एवं विचार गोष्ठियों का आयोजन करना।</li>
            <li>शासन/प्रशासन के साथ सहयोग स्थापित कर अपराध व शोषण रोकने का प्रयास करना।</li>
            <li>
              भारत सरकार द्वारा पारित मानवाधिकार संरक्षण अधिनियम १९९३ का जन-जन में प्रचार प्रसार करना जिससे आम आदमी उसका लाभ प्राप्त कर सके।</li>
          </ul>
        </div>
       
        

      
        <div>

        </div>
      </div>


      <div>
      {/* Donate Button */}
      {showButton && (
        <button
          onClick={handleClick}
          style={{
            position: "fixed",
            bottom: "120px",
            right: "0px",
            backgroundColor: "green",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
          }}
        >
          Donate
        </button>
      )}

      {/* Popup */}
      {showPopup && (
        <div className="popup-container">
          <div className="popup-content">
            <h3>Donation Information</h3>
            <img src={donateQr} alt="" style={{width:"100%",height:"auto", objectFit:"cover" }}/>
            <p>Thank you for your interest in donating!</p>
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
    </div>
  )
}

export default Home
