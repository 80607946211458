import React, { useState } from 'react';
import { db, storage } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import qr from '../images/qr.jpg';
import logo from '../images/logo (2).jpg';
import {v4} from "uuid"


const MemberShip = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    gender: '',
    email: '',
    dob: '',
    membershipType: '',
    qualification: '',
    mobileNumber: '',
    category: '',
    state: '',
    city: '',
    address: '',
    documentType: '',
    documentFront: null,
    documentBack: null,
    idNumber: '',
    photo: null,
    receipt: null
  });

  const [documentFront,setdocumentFront]=useState(null);
  const [documentBack,setdocumentBack]=useState(null);
  const [photo,setphoto]=useState(null);
  const [receipt,setreceipt]=useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      // setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleChangeFirst=(e) =>{
    if (e.target.files[0]) {
      const img=ref(storage,`documents/${v4()}`)
      uploadBytes(img,e.target.files[0]).then(data=>{

        getDownloadURL(data.ref).then(val =>{
          setdocumentFront(val);
          setFormData.documentFront=val;
        })
      })
    }
  }
const handleChangeSecond=(e) =>{
  if (e.target.files[0]) {
    const img=ref(storage,`documents/${v4()}`)
    uploadBytes(img,e.target.files[0]).then(data=>{
      getDownloadURL(data.ref).then(val =>{
        setdocumentBack(val);
        setFormData.documentFront=val;
      })
    })
  }
  }

  const handleChangePhoto=(e) =>{
    if (e.target.files[0]) {
      const img=ref(storage,`photo/${v4()}`)
      uploadBytes(img,e.target.files[0]).then(data=>{
   
        getDownloadURL(data.ref).then(val =>{
          setphoto(val);
          setFormData.photo=val;

        })
      })
    }
  }


  const handleChangeReceipt=(e) =>{
    if (e.target.files[0]) {
      const img=ref(storage,`receipt/${v4()}`)
      uploadBytes(img,e.target.files[0]).then(data=>{
        // console.log(data,"img")
        getDownloadURL(data.ref).then(val =>{
          setreceipt(val);
          setFormData.receipt=val;

        })
      })
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

  
console.log(formData);
    // Save data to Firestore
    await addDoc(collection(db, 'memberships'), {
      ...formData,
      photo,
      receipt,
      documentFront,
      documentBack
    });

    alert('Membership form submitted successfully!');

    setFormData({
        fullName: '',
        gender: '',
        email: '',
        dob: '',
        membershipType: '',
        qualification: '',
        mobileNumber: '',
        category: '',
        state: '',
        city: '',
        address: '',
        documentType: '',
        documentFront: null,
        documentBack: null,
        idNumber: '',
        photo: null,
        receipt: null
      },

      


    );

    setdocumentFront(null);
    setdocumentBack(null);
    setphoto(null);
    setreceipt(null);
  };

  return (
    <div className='membership' style={{ borderRadius: "5px", padding: "2rem 9%", flexWrap: "wrap", display: "flex", justifyContent: "space-evenly", width: "100%", gap: "1rem" }}>
      <form onSubmit={handleSubmit} style={{ flex: "1 1 48%", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", padding: "1rem 5%", border: "1px solid gray" }}>
        <h2 style={{ textDecoration: "underline", color: "red" }}>Get MemberShip</h2>
        <label>Full Name<br />
          <input name="fullName" value={formData.fullName} onChange={handleChange} placeholder='Full name' />
        </label>
        <label>Gender<br />
          <input name="gender" value={formData.gender} onChange={handleChange} placeholder='Select Gender' />
        </label>
        <label>Email<br />
          <input name="email" value={formData.email} onChange={handleChange} placeholder='Email' />
        </label>
        <label>Date of Birth<br />
          <input name="dob" value={formData.dob} onChange={handleChange} placeholder='dd-mm-yyyy' type='date' />
        </label>
        <label>MemberShip Type <br />
          <input name="membershipType" value={formData.membershipType} onChange={handleChange} placeholder='Select Type' />
        </label>
        <label>Qualification<br />
          <input name="qualification" value={formData.qualification} onChange={handleChange} placeholder='Your Qualification' />
        </label>
        <label>Mobile Number<br />
          <input name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} placeholder='Mobile Number' />
        </label>
        <label>Select Category<br />
          <input name="category" value={formData.category} onChange={handleChange} placeholder='Select Category' />
        </label>
        <label>Select State<br />
          <input name="state" value={formData.state} onChange={handleChange} placeholder='Select State' />
        </label>
        <label>City<br />
          <input name="city" value={formData.city} onChange={handleChange} placeholder='city name' />
        </label>
        <label>Full Address<br />
          <input name="address" value={formData.address} onChange={handleChange} placeholder='Full address, state, district, and pin' />
        </label>

        <h2 style={{ fontSize: "20px", textAlign: "left", marginTop: "40px" }}>Select Document and Upload</h2>
        <div style={{ marginTop: "20px", display: "flex", gap: "20px", flexWrap: "wrap" }}>
          <span>
            Adhaar Card
            <input name="documentType" value="Adhaar Card" onChange={handleChange} style={{ marginLeft: "10px" }} type="radio" />
          </span>
          <span>
            Driving Licence
            <input name="documentType" value="Driving Licence" onChange={handleChange} type="radio" style={{ marginLeft: "10px" }} />
          </span>
          <span>
            Voter ID
            <input name="documentType" value="Voter ID" onChange={handleChange} type="radio" style={{ marginLeft: "10px" }} />
          </span>
          <span>
            Passport
            <input name="documentType" value="Passport" onChange={handleChange} type="radio" style={{ marginLeft: "10px" }} />
          </span>
        </div>
        <div style={{ border: "1px solid gray", padding: "20px", marginTop: "20px" }}>
          <label style={{ fontSize: "16px", marginBottom: "20px" }}>
            Document first side
            <input name="documentFront" type="file" onChange={handleChangeFirst} />
          </label>
          <label style={{ fontSize: "16px", marginBottom: "20px" }}>
            Document second side
            <input name="documentBack" type="file" onChange={handleChangeSecond} />
          </label>
          <p style={{ textAlign: "left" }}>file format : jpeg, png, gif, pdf and file size max, 1mb</p>
        </div>

        <label>
          Type Here Your ID Number
          <input name="idNumber" value={formData.idNumber} onChange={handleChange} />
        </label>

        <div style={{ border: "1px solid gray", padding: "20px", marginTop: "20px", marginBottom: "20px" }}>
          <label style={{ fontSize: "16px", marginBottom: "10px" }}>
            Upload Photo
            <input name="photo" type="file" onChange={handleChangePhoto} />
          </label>
          <p style={{ textAlign: "left", marginBottom: "20px" }}>file format : jpeg, png, gif and file size max, 1mb</p>
          <label style={{ fontSize: "16px", marginBottom: "10px" }}>
            Upload Receipt
            <input name="receipt" type="file" onChange={handleChangeReceipt} />
          </label>
          <p style={{ textAlign: "left" }}>file format : jpeg, png, gif and file size max, 1mb</p>
        </div>
        <input type="submit" style={{ margin: "auto", padding: "10px", width: "50%", backgroundColor: "#090457", color: "white", fontSize: "22px", fontWeight: "600", borderRadius: "5px", marginBottom: "20px" }} />
      </form>

      <div style={{ flex: "1 1 48%", border: "1px solid gray", width: "100%", padding: "1rem 5%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
        <h2 style={{ color: "red", textDecoration: "underline" }}>Human Development and Research Services (HDRS)</h2>
        <img src={logo} height="60px" width="100%" alt=".." />
        <div style={{ textAlign: "left" }}>
          <h3>Bank Details</h3>
          <ul style={{ padding: "0px", listStyle: "none", textAlign: 'left' }}>
            <li>
              <em>Name Of Beneficiary: </em>
              Human development and research service
            </li>
            <li><em>Account Number: 924020027156251</em></li>
            <li><em>Name of Bank: AXIS BANK</em></li>
            <li><em>Branch: </em>RAJOUND, KATHAL-136044</li>
            <li><em>IFSC code: UTIB0005311</em></li>
          </ul>
          <img style={{ width: "300px" }} src={qr} alt="..." />
        </div>
        <div>
          <h2 style={{ textAlign: "left" }}>Membership Team Format</h2>
          <ul style={{ padding: "0px", listStyle: "none", textAlign: "left" }}>
            <li>Director</li>
            <li>Deputy Director</li>
            <li>General Secretary (महासचिव)</li>
            <li>Organization secretary (संगठन सचिव)</li>
            <li>Program secretary (कार्यक्रमसचिव)</li>
            <li>Media secretary (मीडिया सचिव)</li>
            <li>Welfare secretary (कल्याण सचिव)</li>
            <li>Law secretary (विधिसचिव)</li>
            <li>Cashier (कोषाध्यक्ष)</li>
            <li>Spokesman (प्रवक्ता)</li>
            <li>other members (अन्य सदस्य)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MemberShip;
