import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = ({ dimensions }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [msg,setMsg]=useState('');
  const navigate = useNavigate();
 

  const handleLogin = (e) => {
    e.preventDefault();
   console.log(username,password)
    if(username==='admin' && password === 'admin@321')
    {
      navigate('/admin');
    }
    else{
      setMsg("Wrong Username and Password");
    }
  };

  return (
    <div className="login-page">
      <form onSubmit={handleLogin}>
        <label>
          Username:
          <input type="text" name="username"  value={username} 
          onChange={(e) => setUsername(e.target.value)}/>
        </label>
        <label>
          Password:
          <input type="password" name="password" value={password} 
              onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type="submit">Login</button>

        <p style={{color:"red",fontSize:"20px"}}>{msg}</p>
      </form>
    </div>
  );
};

export default Login;
