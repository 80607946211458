// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA-U1sjzf2G_zZnG_eSiA2zw-zlmJ--XVw",
  authDomain: "hrds-d5c5f.firebaseapp.com",
  projectId: "hrds-d5c5f",
  storageBucket: "hrds-d5c5f.appspot.com",
  messagingSenderId: "592420842003",
  appId: "1:592420842003:web:4ef930752402d74253ade6",
  measurementId: "G-VST1J0M223"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

export { db, storage };
