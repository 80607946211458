import React, { useEffect, useState } from 'react';
import { db } from "../firebase";
import { collection, getDocs } from 'firebase/firestore';

const ComplaintData = () => {
    const [data, setData] = useState([]);

    const getData = async () => {
        const valRef = collection(db, 'registration');
        const dataDb = await getDocs(valRef);
        const allData = dataDb.docs.map(val => ({ ...val.data(), id: val.id }));
        setData(allData);
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <div>
            <h1>Complaint Data</h1>
            <table>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Subject</th>
                    <th>Message</th>
                    <th>Image</th>
                </tr>
                {data.map(value => (
                <tr key={value.id}>
                    <td>{value.fullName}</td>
                    <td>{value.email}</td>
                    <td>{value.phone}</td>
                    <td>{value.subject}</td>
                    <td>{value.message}</td>
                    <td><img src={value.image} alt="" style={{height:"50px", width:"50px"}}  /></td>
                </tr>
            ))}
            </table>
            
        </div>
    );
};

export default ComplaintData;

